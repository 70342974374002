import React, { useEffect, useState } from 'react'
import { upperCase } from 'lodash'
import Slider from 'react-slick'
import Modal from 'react-modal'

import zoomIn from '../../assets/images/floorplan/zoomIn.webp'
import { CloseOutlined } from '@ant-design/icons'

import './PropertyUnitModal.scss'

// @ts-ignore
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  className: 'modal-slider',
  fade: false,
  pauseOnHover: false
}

const PropertyUnitModal = (props: any) => {
  const [carousel, setCarousel] = useState<null | any>(null)
  const [zoom, setZoom] = useState(false)
  const [windowView, setWindowView] = useState<boolean>()
  const [rotateImage, setRotateImage] = useState<boolean>(false)
  const zoomFunction = () => {
    if (!windowView) setRotateImage(!rotateImage)
    setZoom(!zoom)
  }

  useEffect(() => {
    if (window.screen.availWidth > 600) {
      setWindowView(true)
    } else {
      setWindowView(false)
    }
  }, [])

  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [props.show])

  const customStyle = {
    content: {
      width: props.width || '92%',
      maxWidth: '1329px',
      maxHeight: '735px',
      padding: 0,
      overflow: 'unset'
    }
  }

  return (
    <Modal className="property-unit-modal" isOpen={props.show} style={customStyle} ariaHideApp={false}>
      <span
        className="pum-modal__close"
        onClick={() => {
          props.showModal(false)
          setZoom(false)
          setRotateImage(false)
        }}
      >
        <CloseOutlined className="las la-times" />
      </span>
      <div className="pum-modal__body">
        <Slider ref={(c) => setCarousel(c)} {...settings}>
          {props?.unitPlan?.map((unitPlan: any, index: number) => {
            return (
              <div className="slide-item" key={index}>
                {!zoom ? (
                  <div className="unit-plan">
                    <div className="left">
                      <div className="unit-table">
                        <span className="unit-title">{upperCase(unitPlan?.unitPlan?.unitFloorPlan)}</span>
                        <span className="unit-subtitle">{upperCase(unitPlan?.area?.unitFloorPlanAreaName)}</span>
                        <div className="details" dangerouslySetInnerHTML={{ __html: unitPlan?.unitPlan?.unitFloorPlanDetails }} />
                      </div>
                    </div>
                    <div className="right">
                      <img
                        loading="lazy"
                        src={unitPlan?.imageGallery && unitPlan?.imageGallery[0]?.image}
                        className="modal-image plan"
                        style={{ width: '538px', height: '649px' }}
                      />
                      <button className="zoom-button">
                        <img loading="lazy" className="zoom-icon" onClick={zoomFunction} src={zoomIn} alt="zoom-button" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="unit-plan">
                    <div className="right zoomed-in">
                      <div className="unit-table">
                        <span className="unit-title">{upperCase(unitPlan?.unitPlan?.unitFloorPlan)}</span>
                        <span className="unit-subtitle" onClick={zoomFunction}>
                          &#60; Back
                        </span>
                      </div>
                      <div className="full-image">
                        <PinchZoomPan zoomButtons={false} position={'center'} maxScale={'5'}>
                          <img
                            loading="lazy"
                            src={unitPlan?.imageGallery && unitPlan?.imageGallery[0]?.image}
                            className="modal-image"
                            style={{ width: '538px', height: '649px' }}
                          />
                        </PinchZoomPan>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </Slider>
      </div>
    </Modal>
  )
}

export default PropertyUnitModal
