import React, { useState, useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './PropertyPageSlider.scss'

import PropertyUnitModal from './PropertyUnitModal'
import toCamelCase from '../../content/toCamelCase'

// @ts-ignore
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/scss/alice-carousel.scss'
import HoverContainer from '../HoverContainer/HoverContainer'

const responsive = {
  0: { items: 1 },
  375: { items: 1 },
  1199: { items: 1 },
  1200: { items: 2 }
}

const PropertyPageSlider = ({ floorPlans }: any) => {
  const { imageGallery, unitPlan } = floorPlans?.unitsFloorPlans[0]
  const [showModal, setShowModal] = useState<boolean>(false)
  const [windowDimensions, setWindowDimensions] = useState<any>({})
  const [selectedUnit, setSelectedUnit] = useState<any>([])

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window
      setWindowDimensions({ width, height })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!showModal && Array.isArray(selectedUnit)) {
      setSelectedUnit([])
    }
  }, [showModal])

  const openPlans = (i: number, unitType: any) => {
    setSelectedUnit(unitPlan.filter((unit: any) => toCamelCase(unitType) === toCamelCase(unit?.unitPlan?.unitFloorPlan)))
    setShowModal(true)
  }

  const imageAndAreaData = imageGallery.map((image: any) => ({
    ...image,
    ...unitPlan.find((plan: any) => plan.unitPlan.unitFloorPlan === image.description)
  }))

  const getSliderContent = (item: any, index: number) => {
    const webContent = item.hasOwnProperty('area')
    return (
      <div className="slide-item" id="dragtarget" key={index}>
        {webContent ? (
          <HoverContainer
            property={item}
            image={item?.image}
            openModal={openPlans}
            index={index}
            floorArea={item?.unitPlan.unitFloorPlanTotalArea}
          />
        ) : (
          <>
            <img loading="lazy" src={item?.image} />
            <small>Artist's Perspective</small>
          </>
        )}
        <p className="slider-text" dangerouslySetInnerHTML={{ __html: item?.description === '' ? `&nbsp;` : item?.description }} />
      </div>
    )
  }

  return (
    <>
      <PropertyUnitModal show={showModal} showModal={setShowModal} unitPlan={selectedUnit} />

      <div className="property-page-slider-container">
        <div className="property-page-slider">
          <h3 className="view-floorplans">View FloorPlans</h3>
          <div className="slider-wrapper-desktop">
            <AliceCarousel
              mouseTracking
              swipeDelta={0}
              swipeExtraPadding={0}
              infinite
              autoPlay
              autoPlayStrategy="default"
              controlsStrategy="responsive"
              autoPlayInterval={4500}
              animationDuration={3000}
              responsive={responsive}
              disableDotsControls
              paddingRight={270}
            >
              {imageAndAreaData?.map((item: any, index: number) => {
                return getSliderContent(item, index)
              })}
            </AliceCarousel>
          </div>

          <div className="slider-wrapper-mobile">
            <AliceCarousel
              mouseTracking
              swipeDelta={0}
              swipeExtraPadding={0}
              infinite
              autoPlay
              autoPlayStrategy="default"
              controlsStrategy="responsive"
              autoPlayInterval={4500}
              animationDuration={3000}
              disableButtonsControls
              responsive={responsive}
              disableDotsControls
            >
              {imageGallery?.map((item: any, index: number) => {
                return getSliderContent(item, index)
              })}
            </AliceCarousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyPageSlider
