import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { find } from 'lodash'

import { ArticleComponentType } from '../../shared/types'
import loadable from '@loadable/component'

import PropertyPageContent from './component/PropertyPageContent'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const SEO = loadable(() => import('../../components/SEO/SEO'))
const Banner = loadable(() => import('../../components/ContentComponents/Banner/Banner'))

import './PropertyPage.scss'

const PropertyPage = ({ pageContext: { property, dataContent } }: any) => {
  const content = dataContent[0]
  const unit = property?.propertyUnits[0]

  const [showModal, toggleModal] = useState<boolean>(false)
  const constructionImage = unit?.constructionUpdates?.imageGallery ? unit?.constructionUpdates?.imageGallery[0] : null
  const [propertyComponents, setPropertyComponents] = useState<ArticleComponentType[] | null>(null)
  const [brochureComponent, setBrochureComponent] = useState<ArticleComponentType | null>(null)
  const [brochureTableComponent, setBrochureTableComponent] = useState<ArticleComponentType | null>()

  const setWebData = () => {
    let mobileData = document.getElementsByTagName('h2')
    let webData = document.getElementsByClassName('description')
    for (let i = 0; i < webData.length; i++) {
      webData.item(i)?.getElementsByTagName('p').item(0)?.setAttribute('style', 'display: block;')
    }
    mobileData?.item(0)?.setAttribute('style', 'display: none;')
  }

  const setMobileData = () => {
    let mobileData = document.getElementsByTagName('h2')
    let webData = document.getElementsByClassName('description')
    for (let i = 0; i < webData.length; i++) {
      webData.item(i)?.getElementsByTagName('p').item(0)?.setAttribute('style', 'display: none;')
    }
    mobileData
      .item(0)
      ?.setAttribute(
        'style',
        'display: block; text-transform: initial; font-family: Frutiger-Light; font-size: 4vw; color: #f2f2f2; white-space: normal'
      )
  }

  const getVirtualTourImg = (): any => {
    const gallery = unit?.overviewDetails?.imagesGallery
    const data = find(gallery, { description: 'virtual_tour' })
    return data ? data : ''
  }

  const setData = () => {
    if (window.screen.availWidth >= 1200) {
      setWebData()
    } else {
      setMobileData()
    }
  }

  useEffect(() => {
    setData()
    window.addEventListener('resize', setData)
    return () => window.removeEventListener('resize', setData)
  }, [propertyComponents])

  useEffect(() => {
    const components = dataContent[0]?.components ? [...dataContent[0].components] : []
    const secondToLastComponent = components[components?.length - 2]
    const lastComponent = components[components?.length - 1]

    if (components.length > 0 || components !== undefined) {
      if (lastComponent?.componentType === 'COMPONENT-1' && secondToLastComponent?.componentType === 'COMPONENT-13') {
        const brochure = components.pop()
        const table = components.pop()
        setPropertyComponents(components)
        setBrochureComponent(brochure)
        setBrochureTableComponent(table)
      } else {
        setPropertyComponents(components)
      }
    } else {
      navigate('/')
    }
  }, [dataContent])

  const seoTitle = property?.propertyUnits[0]?.overviewDetails?.overviewDetails?.seoTitle
  const defaultTitle = content?.contentDefault ? content?.contentDefault : `${content?.title} - Ayala Land Premier`

  const seoDescription = property?.propertyUnits[0]?.overviewDetails?.overviewDetails?.seoDescription
  const defaultDescription = content?.description?.replace(/\n/g, '')

  return (
    <>
      <Layout
        {...{
          props: {
            layoutStyle: 'property-page-layout'
          }
        }}
      >
        <SEO
          title={seoTitle ? seoTitle : defaultTitle}
          description={seoDescription ? seoDescription : defaultDescription}
          image={content?.image}
        />
        <div className="slider-property-page-container">
          <Banner
            type={content?.videoLink ? 'video' : 'image'}
            link={content?.videoLink ? content?.videoLink : content?.image}
            caption={content?.subTitle.replace(/<\/?[^>]+(>|$)/g, '')}
            bannerImageAltText={content?.altText}
          />
        </div>

        <PropertyPageContent
          propertyComponents={propertyComponents}
          property={property}
          unit={unit}
          getVirtualTourImg={getVirtualTourImg}
          brochureComponent={brochureComponent}
          constructionImage={constructionImage}
          toggleModal={toggleModal}
          showModal={showModal}
          brochureTableComponent={brochureTableComponent}
        />
      </Layout>
    </>
  )
}

export default PropertyPage
